import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import outStory from '../img/about/infos-conselho.png'
import timeLine from '../img/about/time-line.png'
import etica from '../img/about/etica.png'
import governanca from '../img/about/governanca.png'
import idenpendencia from '../img/about/idenpendencia.png'
import transparencia from '../img/about/transparencia.png'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import pdf2020 from '../pdf/2020.pdf';
import pdf2019 from '../pdf/2019.pdf';
import pdf2018 from '../pdf/2018.pdf';
import pdf2017 from '../pdf/2017.pdf';
import pdf2016 from '../pdf/2016.pdf';
import pdf2015 from '../pdf/2015.pdf';
import pdf2014 from '../pdf/2014.pdf';
import pdf2013 from '../pdf/2013.pdf';


import './product-page.scss';

// remove me, as I'm the about page


export const ProductPageTemplate = ({
}) => {


  const [isModalActive, setIsModalActive] = useState(false);
  const [userModal, setUserModal] = useState(null);

  const handleViewMore = (data) => {
    setUserModal(data)
    setIsModalActive(true)
  }

  const close = () => {
    setIsModalActive(false)
  }

  const handleAccordion = (data) => {
    data.status = !data.status
    setAccordion([...accordion])
  }

  const [accordion, setAccordion] = useState([
    {
      id: 1,
      name: 'Conselho Deliberativo',
      status: true,
      itens: [
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro qualquer',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        }
      ]
    },
    {
      id: 2,
      name: 'CONSELHO FISCAL',
      status: false,
      itens: [
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        }
      ]
    },
    {
      id: 3,
      name: 'DIRETORIA',
      status: false,
      itens: [
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        },
        {
          img: require('../img/about/perfil.png'),
          imgModal: require('../img/about/image-modal.png'),
          name: 'Pedro adsasd',
          charge: 'Presidente',
          description: 'André Clark nasceu em São Paulo. A carreira do executivo iniciou na indústria de Papel & Celulose em 1995. Possui 17 anos de experiência nas áreas de Energia, Petróleo & Gás, Manufatura, Logística e Infraestrutura. Foi CEO da Acciona para o Brasil, Bolívia, Uruguai e Paraguai até 2016. Atualmente, é Presidente e CEO da Siemens no Brasil, desde 1º de novembro de 2017. André é bacharel em Engenharia Química pela Escola Politécnica da Universidade de São Paulo (USP) e possui MBA em Finanças e Gestão de Operações pela New York University Stern School of Business.'
        }
      ]
    },
  ])

  return (
    <div>
      <div className="background-banner">
        <div className="background-banner_title">
          <h1 className="is-size-3 is-bold-light">
            Oferecemos uma gestão impecável
          </h1>
          <span className="background-banner_subtitle">dos recursos investidos no Fundo</span>
        </div>
      </div>
      <section className="our-story">
        <div className="container">
          <h2 className="text-primary">NOSSA HISTÓRIA</h2>
        </div>
        <img src={timeLine} alt="teste"></img>
      </section>
      <section className="out-mission">
        <div className="container">
          <p className="out-mission_title">Nossa Missão</p>
          <p className="out-mission_description">Desenvolver o potencial dos alunos da Poli, contribuindo com a excelência de sua formação.</p>
          <div className="out-mission_cards d-flex">
            <div className="out-mission_card">
              <img src={etica} alt="teste"></img>
              <p className="out-mission_card_title">ÉTICA</p>
            </div>
            <div className="out-mission_card">
              <img src={governanca} alt="teste"></img>
              <p className="out-mission_card_title">Governança</p>
            </div>
            <div className="out-mission_card">
              <img src={idenpendencia} alt="teste"></img>
              <p className="out-mission_card_title">Idenpendência</p>
            </div>
            <div className="out-mission_card">
              <img src={transparencia} alt="teste"></img>
              <p className="out-mission_card_title">Transparência</p>
            </div>
          </div>
        </div>
      </section>
      <section className="corporate-governance">
        <div className="container">
          <h2 className="corporate-governance_title text-primary">GOVERNANÇA CORPORATIVA</h2>
          <p className="corporate-governance_description">O Fundo Patrimonial Amigos da Poli foi concebido de maneira a possuir uma estrutura e um modelo de atuação diferenciados, que pudessem trazer à Escola Politécnica uma inteligência na captação e alocação de recursos que não é contemplada nos moldes de gestão habituais. Dito isso, possuímos uma estrutura organizacional sofisticada, com órgãos de Conselho, Comitês e Diretorias em linha com as melhores práticas de governança.</p>
        </div>
        <img className="mb-50" src={outStory} alt="teste"></img>
        <div className="corporate-governance_button">
          <a href="http://estatuto.amigosdapoli.com.br/" target="_black">baixe o nosso estatuto</a>
        </div>
      </section>
      <section className="board">
        <div className="container">
          <h2 className="board_title text-primary">CONSELHEIROS E DIRETORIA</h2>
          <p className="board_description">Conheça nossa liderança</p>
        </div>

        <div className="container">
          <div className="accordion">
            {accordion.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <div className="board_accordion">

                    <div className="board_info" onClick={() => handleAccordion(item)}>
                      <div className="board_info_title">
                        {item.name}
                      </div>
                      <div className="board_info_icon">
                        <FontAwesomeIcon icon={item.status ? faAngleUp : faAngleDown} />
                      </div>
                    </div>
                    <div className="board_info_accordion justify-content-center">
                      {item.status && item.itens.map((user, i) => {
                        return (
                          <div className="board_info_content" key={i}>
                            <div className="board_info_content_image">
                              <img src={user.img} alt="teste"></img>
                            </div>
                            <div>
                              <p className="board_info_content_title">{user.name}</p>
                              <span className="board_info_content_office">{user.charge}</span>
                              <span className="board_info_content_button text-primary" onClick={() => handleViewMore(user)}>Ver perfil</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </React.Fragment>
              )
            })}

          </div>

        </div>

      </section>
      <section className="part-it">
        <div className="container">
          <div className="part-it_center">
            <p className="part-it_title">Faça parte do Amigos da Poli</p>
            <p className="part-it_description">Você pode ajudar nosso fundo como voluntário ou através de doações. Faça parte e colabore</p>
            <div className="d-flex">
              <a href="/donation/" target="_black" className="part-it_button">Faça sua doação</a>
              <a href="mailto:contato@amigosdapoli.com.br" className="part-it_button">Seja um voluntário</a>
            </div>

          </div>
        </div>
      </section>
      {isModalActive &&
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="modal_header">
              <span onClick={() => close()}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
            <div className="modal_body">
              <div className="modal_image">
                <img src={userModal.imgModal} alt="teste"></img>
              </div>
              <div class="modal_content">
                <p className="modal_content_title">{userModal.name}</p>
                <span className="modal_content_office">{userModal.charge}</span>
                <span className="modal_content_description">{userModal.description}</span>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close"></button>
        </div>
      }

      <section className="annual-reports">
        <div>
          <h2 className="text-primary">R</h2>
          <p className="annual-reports_description">Veja tudo que realizamos nos últimos anos</p>
          <div className="d-flex justify-content-center">
            <a href={pdf2013} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2013</a>
            <a href={pdf2014} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2014</a>
            <a href={pdf2015} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2015</a>
            <a href={pdf2016} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2016</a>
            <a href={pdf2017} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2017</a>
            <a href={pdf2018} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2018</a>
            <a href={pdf2019} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2019</a>
            <a href={pdf2020} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">2020</a>
          </div>
          <div className="d-flex">
              <a href="/donation/" target="_black" className="part-it_button">demonstrativos financeiros</a>
          </div>
        </div>
      </section>




    </div >
  )
}

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  console.log(frontmatter)
  return (
    <Layout>
      <ProductPageTemplate
      />
    </Layout>
  )
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProductPage

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        main {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        testimonials {
          author
          quote
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`
